@import '~@fortawesome/fontawesome-free/css/all.css';
@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

::placeholder {
    @apply text-cbogray;
}

::-ms-input-placeholder {
    @apply text-cbogray;
}

body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0;
    @apply text-carbon;
}

body>header,
body>footer {
    flex: 0 1 auto;
}

body>#main {
    flex: 1 1 auto;
}

input{
	@apply text-carbon p-2 rounded-lg;
}

.required:after {
	content:" *";
	color: red;
}

.ml-16pc{
	margin-left: 16.666666%;
}

.button-principal {
	@apply bg-gold text-carbon py-2 px-3 rounded-lg font-bold hover:bg-carbon-lightest hover:text-white;
}

.catalog-button {
	@apply bg-carbon text-white py-2 px-3 rounded-lg font-bold hover:bg-cbogray hover:text-carbon;
}

.account-menu{
	@apply flex h-full relative rounded-lg hover:bg-gold hover:text-carbon
}

input[type=checkbox].switch{
	height: 0;
	width: 0;
	visibility: hidden;
}

label.switch-label {
	@apply bg-carbon-lighter block cursor-pointer rounded-full relative;
	text-indent: -9999px;
	width: 50px;
	height: 30px;
	position: relative;
}

label.switch-label:after {
	@apply bg-cbogray block cursor-pointer rounded-full absolute;
	content: '';
	top: 5px;
	left: 5px;
	width: 20px;
	height: 20px;
	transition: 0.3s;
}

input.switch:checked + label.switch-label {
	@apply bg-gold;
}

input.switch:checked + label.switch-label:after {
	left: calc(100% - 5px);
	transform: translateX(-100%);
}

label.switch-label:active:after {
	width: 20px;
}

h1{
	text-transform: uppercase;
	@apply text-carbon;
}

h2{
	font-weight: normal;
	margin-bottom: 0;
}

h3{
	font-weight: normal;
}

.max-w-xxxs{
	max-width: 7rem;
}

.fa{
	font-size: 32px;
	padding-right: 15px;
}

.text-xs{
	font-size: 0.75rem;
	line-height: 1rem;
}

.text-xxs{
	font-size: 0.625rem;
	line-height: 0.875rem;
}

.text-xxxs{
	font-size: 0.5rem;
	line-height: 0.75rem;
}

#products{
	@apply bg-red-500 rounded-full text-xxs absolute py-px px-2;
	left: 50px;
	bottom: 10px;
}

.loader {
	border: 16px solid #2d2d2d;
	border-top: 16px solid #F3BF09;
	border-radius: 50%;
	width: 120px;
	height: 120px;
	animation: spin 2s linear infinite;
}

.z-90{
	z-index: 90;
}

@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}

@media (max-width: 1440px) {
	label.switch-label {
		height: 20px;
		width: 35px;
	}

	label.switch-label:after {
		top: 3px;
		left: 3px;
		width: 14px;
		height: 14px;
	}

	#products{
		@apply px-1;
		font-size: 0.5rem;
		line-height: 0.5rem;
		left: 40px;
		bottom: 5px;
	}

	#search_query, #priceFilterMini, #priceFilterMax{
		@apply p-1 h-1/2;
	}

	#search_submit, #PriceFilter, #ResetPriceFilter{
		@apply py-1;
	}
}